import validateLabel from '@imt/vue-web-form-editor/src/plugins/vee-validate';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {extend} from 'vee-validate';
import {max, alpha_num, regex, required, excluded, max_value, min_value, numeric} from 'vee-validate/dist/rules'; // eslint-disable-line camelcase
import Vue from 'vue';


extend('alpha_num', alpha_num);
extend('excluded', excluded);
extend('max_value', max_value);
extend('min_value', min_value);
extend('numeric', numeric);
extend('regex', regex);
extend(validateLabel.name, validateLabel.validator);

extend('max', {
    ...max,
    params: ['length'],
    message: 'This field must be less than {length} characters'
});

extend('required', {
    ...required,
    message: 'This field is required'
});

extend('month_year_input', {
    validate(value) {
        let digitValue = value.toString().replace(/\D/g,'/'),
            sepValues = digitValue.split('/');

        const month = sepValues[0],
            year = sepValues[1];

        return year > 1000 && year < 10000 && month > 0 && month < 13;
    },
    message: 'Invalid format (must be MM/YYYY format)',
});

extend('class_code_input', {
    params: ['old'],
    validate(value, { old }) {
        const pattern = new RegExp('^(?:(\\d+)|(\\d+)-([a-zA-Z]+)|(\\d+)-(\\d+))$');

        return value !== old ? pattern.test(value) : false;
    },
    message: 'Invalid format'
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
