import {UUID_REGEX} from '@imt/vue-toolbox/src/utils/index';

export default [
    {
        path: '/',
        redirect: {
            name: 'admin',
        },
    },
    {
        path: '/class-codes',
        redirect: {
            name: 'admin.class-codes',
        },
    },
    {
        path: '/admin',
        children: [
            {
                path: '',
                name: 'admin',
                redirect: {
                    name: 'admin.forms',
                },
            },
            {
                path: 'forms',
                name: 'admin.forms',
                component: () => import(/* webpackChunkName: "FBase" */ '@/views/FBase.vue'),
                meta: {
                    title: 'Forms',
                },
            },
            {
                path: 'class-codes',
                name: 'admin.class-codes',
                component: () => import(/* webpackChunkName: "FBase" */ '@/views/FBase.vue'),
                meta: {
                    title: 'Class Codes',
                },
            },
            {
                path: `policy-system/:policySystemId(${UUID_REGEX})`,
                component: () => import(/* webpackChunkName: "TBaseView" */ '@imt/vue-toolbox/src/components/TBaseView.vue'),
                children: [
                    {
                        path: `forms`,
                        meta: {
                            title: 'Forms',
                        },
                        component: () => import(/* webpackChunkName: "FBase" */ '@/views/FBase.vue'),
                        children: [
                            {
                                path: '',
                                name: 'admin.forms.list',
                                component: () => import(/* webpackChunkName: "FForms" */ '@/views/forms/FForms.vue'),
                                meta: {
                                    title: 'Forms',
                                },
                                children: [
                                    {
                                        path: `:formId(${UUID_REGEX})`,
                                        name: 'admin.forms.options',
                                        component: () => import(/* webpackChunkName: "FFormOptions" */ '@/components/forms/FFormOptions.vue'),
                                        meta: {
                                            title: 'Forms',
                                        },
                                    },
                                    {
                                        path: 'search',
                                        name: 'admin.forms.search',
                                        component: () => import(/* webpackChunkName: "FAdvancedSearch" */ '@/components/forms/FAdvancedSearch.vue'),
                                        meta: {
                                            title: 'Forms',
                                        },
                                    },
                                    {
                                        path: 'new',
                                        name: 'admin.forms.add',
                                        component: () => import(/* webpackChunkName: "FFormAddModal" */ '@/components/forms/FFormAddModal.vue'),
                                        meta: {
                                            title: 'Forms',
                                        },
                                    },
                                ],
                            },
                            {
                                path: `:formId(${UUID_REGEX})/view/:versionId(${UUID_REGEX})`,
                                name: 'form.view.web-form',
                                component: () => import(/* webpackChunkName: "FViewWebForm" */ '@/views/FViewWebForm.vue'),
                                meta: {
                                    title: 'Forms',
                                },
                            },
                            {
                                path: `:formId(${UUID_REGEX})/edit/:versionId(${UUID_REGEX})`,
                                name: 'form.edit.web-form',
                                component: () => import(/* webpackChunkName: "FWebFormEditor" */ '@/views/FWebFormEditor.vue'),
                                meta: {
                                    title: 'Forms',
                                },
                            },
                            {
                                path: 'bulk-edit',
                                name: 'admin.forms.bulk-edit',
                                component: () => import(/* webpackChunkName: "FBulkEditModal" */ '@/components/forms/FBulkEditModal.vue'),
                                meta: {
                                    title: 'Forms',
                                },
                            },
                        ],
                    },
                    {
                        path: `class-codes`,
                        meta: {
                            title: 'Class Codes',
                        },
                        component: () => import(/* webpackChunkName: "FBase" */ '@/views/FBase.vue'),
                        children: [
                            {
                                path: '',
                                name: 'admin.class-codes.list',
                                component: () => import(/* webpackChunkName: "FClassCodes" */ '@/views/class-codes/FClassCodes.vue'),
                                meta: {
                                    title: 'Class Codes',
                                },
                                children: [
                                    {
                                        path: `:classCodeId(${UUID_REGEX})`,
                                        name: 'admin.class-codes.options',
                                        component: () => import(/* webpackChunkName: "FClassCodeOptions" */ '@/components/class-codes/FClassCodeOptions.vue'),
                                        meta: {
                                            title: 'Class Codes',
                                        },
                                    },
                                    {
                                        path: 'search',
                                        name: 'admin.class-codes.search',
                                        component: () => import(/* webpackChunkName: "FClassCodesAdvancedSearch" */ '@/components/class-codes/FClassCodesAdvancedSearch.vue'),
                                        meta: {
                                            title: 'Class Codes',
                                        },
                                    },
                                    {
                                        path: 'new',
                                        name: 'admin.class-codes.add',
                                        component: () => import(/* webpackChunkName: "FClassCodeAddModal" */ '@/components/class-codes/FClassCodeAddModal.vue'),
                                        meta: {
                                            title: 'Class Codes',
                                        },
                                    },
                                ],
                            },
                            {
                                path: `:classCodeId(${UUID_REGEX})/view/:versionId(${UUID_REGEX})`,
                                name: 'class-code.view.web-form',
                                component: () => import(/* webpackChunkName: "FViewWebForm" */ '@/views/FViewWebForm.vue'),
                                meta: {
                                    title: 'Class Codes',
                                },
                            },
                            {
                                path: `:classCodeId(${UUID_REGEX})/edit/:versionId(${UUID_REGEX})`,
                                name: 'class-code.edit.web-form',
                                component: () => import(/* webpackChunkName: "FWebFormEditor" */ '@/views/FWebFormEditor.vue'),
                                meta: {
                                    title: 'Class Codes',
                                },
                            },
                        ],
                    },
                    {
                        path: `/merge`,
                        name: 'merge',
                        component: () => import(/* webpackChunkName: "FMerge" */ '@/views/FMerge.vue'),
                    },
                ]
            },
            {
                path: 'configurations',
                name: 'admin.configurations',
                component: () => import(/* webpackChunkName: "FConfigurations" */ '@/views/FConfigurations.vue'),
                meta: {
                    title: 'Application Configuration',
                },
            },
        ],
        component: () => import(/* webpackChunkName: "FAdmin" */ '@/views/FAdmin.vue'),
    },
    {
        path: '*',
        component: () => import(/* webpackChunkName: "KCNotFound" */ '@imt/vue-kit-car/src/components/errors/NotFound.vue'),
        meta: {
            title: 'Not Found'
        },
    },
];
