import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faCheckSquare,
    faClone,
    faEye,
    faListAlt,
    faSquare,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faBuilding,
    faCog,
    faLink,
    faPencil,
    faPlus,
    faSearch,
    faServer,
    faSpinner,
    faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
    faBuilding,
    faCheckSquare,
    faClone,
    faCog,
    faEye,
    faLink,
    faListAlt,
    faPlus,
    faPencil,
    faSearch,
    faServer,
    faSpinner,
    faSquare,
    faTimes,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
