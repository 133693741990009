<template>
    <div
        id="app"
        class="container-fluid"
    >
        <div
            v-if="permissionsLoaded && userHasPermission"
            class="row"
        >
            <div class="col-auto px-0">
                <AMMenu
                    @menu-toggled="onMenuToggled($event)"
                />
            </div>
            <div class="col px-0 d-flex flex-column">
                <AMInAppAlerts data-cy="inAppAlerts" />
                <AMHeader
                    :title="pageTitle"
                >
                    <template slot="header-items">
                        <BTooltip
                            custom-class="header-tooltip"
                            placement="left"
                            title="Application Configuration"
                            target="configurationCog"
                            boundary-padding="0"
                        />

                        <li class="nav-item py-2">
                            <RouterLink
                                v-if="userHasConfigurationPermission"
                                id="configurationCog"
                                :to="{name: 'admin.configurations'}"
                                data-cy="applicationConfigurationCog"
                            >
                                <FontAwesomeIcon
                                    icon="cog"
                                    class="fa fa-fw mx-1 fa-2x extra-item align-self-center"
                                />
                            </RouterLink>
                        </li>
                    </template>
                </AMHeader>

                <RouterView />

                <div class="row footer m-0 mt-auto">
                    <div class="col-12 col-lg-4 text-center text-lg-left">
                        <a
                            href="https://ticket.imtins.com/status/endpoints"
                            class="btn btn-link p-0"
                        >
                            Platform Status
                        </a>
                    </div>
                    <div class="col text-muted text-center">
                        <span class="copyright">&copy;{{ copyrightText }}</span> <span class="d-none d-lg-inline">All rights reserved.</span>
                    </div>
                    <div class="col-12 col-lg-4 text-center text-lg-right">
                        <button
                            ref="backToTop"
                            v-scroll-to="'#app'"
                            class="btn btn-link btn-back-to-top p-0"
                            @click="$ga.event('Admin View', 'click', 'Back to Top')"
                        >
                            <FontAwesomeIcon
                                icon="caret-up"
                            />
                            Back to top
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <Forbidden
            v-else-if="permissionsLoaded && !userHasPermission"
            class="vh-100 justify-content-center"
        />
    </div>
</template>

<script>
    import {library} from '@fortawesome/fontawesome-svg-core';
    import {faCaretUp, faCog} from '@fortawesome/pro-solid-svg-icons';
    import AMHeader from '@imt/vue-admin-menus/src/components/AMHeader.vue';
    import AMInAppAlerts from '@imt/vue-admin-menus/src/components/AMInAppAlerts.vue';
    import AMMenu from '@imt/vue-admin-menus/src/components/AMMenu.vue';
    import authMixin from '@imt/vue-toolbox/src/mixins/auth';
    import dayjs from 'dayjs';
    import Vue from 'vue';
    import {mapMutations} from 'vuex';

    var VueScrollTo = require('vue-scrollto');

    library.add(faCaretUp, faCog);
    Vue.use(VueScrollTo, {
        duration: 300,
        easing: 'ease',
        offset: 0,
        force: true,
        cancelable: true,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true
    });

    export default {
        name: 'App',
        components: {
            AMHeader,
            AMInAppAlerts,
            AMMenu,
            'Forbidden': () => import('@imt/vue-kit-car/src/components/errors/Forbidden.vue'),
        },
        mixins: [
            authMixin,
        ],
        computed: {
            copyrightText: () => `Copyright ${dayjs().year()} IMT Insurance.`,
            pageTitle() {
                return this.$route?.meta?.title || '';
            },
            permissionsLoaded() {
                return !!this.user?.id;
            },
            userHasConfigurationPermission() {
                return this.userCan('configuration_access');
            },
            userHasPermission() {
                return this.userCan('forms_access');
            },
        },
        methods: {
            onMenuToggled($event) {
                this.SET_ADMIN_SIDE_MENU_COLLAPSED($event);
            },
            ...mapMutations([
                'SET_ADMIN_SIDE_MENU_COLLAPSED',
            ]),
        },
    };
</script>

<style lang="sass">
    @import "~@imt/vue-kit-car/src/sass/kit-car"

    .fa
        outline: none
</style>
