import {VueMaskDirective} from 'v-mask';
import Vue from 'vue';
import VueAnalytics from 'vue-analytics';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/config';
import '@/plugins/bootstrap-vue';
import '@/plugins/font-awesome';
import '@/plugins/sentry';
import '@/plugins/vee-validate';

// Styling
import '../sass/css/form.scss';

Vue.config.productionTip = false;

Vue.directive('mask', VueMaskDirective);

Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_ANALYTICS_ID,
    debug: {enabled: false}
});

new Vue({
    router,
    store,
    created() {
        this.$store.dispatch('toolbox/fetchUser');
    },
    render: h => h(App)
}).$mount('#app');
