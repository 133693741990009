export const formFilters = {
    'search': 'filter[text]',
    'status': 'filter[status]',
    'formType': 'filter[form_type]',
    'policySystemId': 'filter[policy_system_id]',
    'state': 'filter[state]',
    'companyId': 'filter[company_id]',
    'editionDate__gte': 'filter[edition_date__gte]',
    'editionDate__lte': 'filter[edition_date__lte]',
    'effectiveDate__gte': 'filter[effective_date__gte]',
    'effectiveDate__lte': 'filter[effective_date__lte]',
    'obsoleteAt__gte': 'filter[obsolete_at_date__gte]',
    'obsoleteAt__lte': 'filter[obsolete_at_date__lte]',
    'number': 'filter[number]',
    'rndName': 'filter[rnd_name]',
    'proposalName': 'filter[proposal_name]',
    'screenName': 'filter[screen_name]',
    'helpWording': 'filter[help_wording]',
    'csrHelpWording': 'filter[csr_help_wording]',
    'internalNotes': 'filter[internal_notes]',
    'inclusionMethod': 'filter[inclusion_method]',
    'locationStates': 'filter[location_states]',
    'mandatory': 'filter[mandatory]',
    'userAvailability': 'filter[user_availability]',
    'showInAgentDashboard': 'filter[show_in_agent_dashboard]',
    'agentDashboardAvailability': 'filter[agent_dashboard_availability]',
    'showInPolicySystem': 'filter[show_in_policy_system]',
    'hasPageVariableData': 'filter[has_page_variable_data]',
    'shouldPrintVariableData': 'filter[should_print_variable_data]',
    'shouldPrintAtRenewal': 'filter[should_print_at_renewal]',
    'requiresSignature': 'filter[requires_signature]',
};

export const classCodeFilters = {
    'search': 'filter[text]',
    'status': 'filter[status]',
    'keyword': 'filter[keyword]',
    'code': 'filter[code]',
    'title': 'filter[title]',
    'description': 'filter[description]',
    'helpWording': 'filter[help_wording]',
    'csrHelpWording': 'filter[csr_help_wording]',
    'policySystemId': 'filter[policy_system_id]',
    'state': 'filter[state]',
    'companyId': 'filter[company_id]',
    'codeType': 'filter[code_type]',
    'csrOnly': 'filter[csr_only]',
    'effectiveDate__gte': 'filter[effective_date__gte]',
    'effectiveDate__lte': 'filter[effective_date__lte]',
};
