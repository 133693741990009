import alerts from '@imt/vue-admin-menus/src/store/alerts';
import notifications from '@imt/vue-admin-menus/src/store/notifications';
import {configuration} from '@imt/vue-configuration/src/store';
import {asString, defaultParamMap} from '@imt/vue-list/src/utils/queryParams';
import toolbox from '@imt/vue-toolbox/src/store';
import utils, {axiosWithAuth} from '@imt/vue-toolbox/src/utils';
import {vueVersioning} from '@imt/vue-versioning/src/store';
import {webFormEditor} from '@imt/vue-web-form-editor/src/store';
import kebabCase from 'lodash/kebabCase';
import Vue from 'vue';
import Vuex from 'vuex';

import { classCodeFilters, formFilters } from '@/filters';

Vue.use(Vuex);

export const authedAxios = axiosWithAuth();

export const getters = {
    dashAvailableOptions() {
        return [
            {
                label: 'All',
                value: 'all'
            },
            {
                label: 'CSR only',
                value: 'csr'
            },
            {
                label: 'None',
                value: null
            },
        ];
    },
    formOptions() {
        return [
            {
                label: 'Additional Insured',
                value: 'Additional Insured'
            },
            {
                label: 'Application',
                value: 'Application'
            },
            {
                label: 'Coverage',
                value: 'Coverage'
            },
            {
                label: 'Exclusion',
                value: 'Exclusion'
            },
            {
                label: 'Endorsement',
                value: 'Endorsement'
            },
        ];
    },
    getCompanyName: (state, gets) => (companyId) => {
        return gets['toolbox/formattedCompanies'].find(c => c.id == companyId)?.name; // eslint-disable-line eqeqeq
    },
    getPolicySystemTitle: (state, gets) => (policySystemId) => {
        return gets['toolbox/formattedPolicySystems'].find(p => p.id === policySystemId)?.title;
    },
    getStates(state, gets) {
        let states = {};
        gets['toolbox/formattedCompanies'].forEach(c => {
            c.licensedStates.forEach(s => {
                states[s.abbreviation.toLowerCase()] = s.name;
            });
        });

        return states;
    },
    statusOptions() {
        return {
            not_published: 'Not Published',
            scheduled: 'Scheduled',
            published: 'Published',
            published_with_draft: 'Published With Draft',
            obsolete: 'Obsolete',
            conflict: 'Conflict'
        };
    },
    userAvailableOptions() {
        return [
            {
                label: 'All',
                value: 'all'
            },
            {
                label: 'CSR only',
                value: 'csr'
            },
            {
                label: 'Agents on override',
                value: 'override'
            },
            {
                label: 'None',
                value: null
            },
        ];
    },
    yesNoOptions() {
        return [
            {
                label: 'Yes',
                value: true
            },
            {
                label: 'No',
                value: false
            },
        ];
    },
};


export const actions = {
    async fetchDiffs(_, {itemType, itemId, oldVersionId, newVersionId, policySystemId}) {
        const response = await authedAxios.get(`/policy-systems/${policySystemId}/${kebabCase(itemType)}s/${itemId}/versions/${oldVersionId}/diffs/${newVersionId}`);

        return response.data;
    },
    async fetchClassCodes({commit, state}, queryObject) {
        let queryString = asString(queryObject, {
                ...defaultParamMap,
                ...classCodeFilters
            }),
            url = `/policy-systems/${state.policySystem}/class-codes/?include=versions.updated_by,versions.created_by${queryString}`;

        // Fix the formatting of double-underscore query parameters in the query string.
        url = url.replace(/date_([g|l]te)/g, 'date__$1');

        const response = await authedAxios.get(url);

        commit('SET_DATA', {field: 'classCodes', data: utils.dataFormatter.deserialize(response.data) || []});
        commit('SET_DATA', {field: 'totalClassCodeCount', data: response.data.meta.pagination.count});

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchClassCode(_, {classCodeId, policySystemId}) {
        const response = await authedAxios.get(`/policy-systems/${policySystemId}/class-codes/${classCodeId}/?include=versions.updated_by,versions.created_by`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchForm(_, {formId, policySystemId}) {
        const response = await authedAxios.get(`/policy-systems/${policySystemId}/forms/${formId}/?include=versions.attachment,versions.updated_by,versions.created_by`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchFormVersion(_, {formId, versionId, policySystemId}) {
        const response = await authedAxios.get(`/policy-systems/${policySystemId}/forms/${formId}/versions/${versionId}/?include=created_by,updated_by,attachment,web_form.sections.input_groups.inputs.options`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchForms({commit, state}, queryObject) {
        let queryString = asString(queryObject, {
                ...defaultParamMap,
                ...formFilters
            }),
            url = `/policy-systems/${state.policySystem}/forms/?include=versions.attachment,versions.updated_by,versions.created_by${queryString}`;

        // Fix the formatting of double-underscore query parameters in the query string.
        url = url.replace(/date_([g|l]te)/g, 'date__$1');

        const response = await authedAxios.get(url);

        commit('SET_DATA', {field: 'forms', data: utils.dataFormatter.deserialize(response.data)});
        commit('SET_DATA', {field: 'totalFormCount', data: response.data.meta.pagination.count});

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchClassCodeVersion(_, {classCodeId, versionId, policySystemId}) {
        const response = await authedAxios.get(`/policy-systems/${policySystemId}/class-codes/${classCodeId}/versions/${versionId}?include=created_by,updated_by,web_form.sections.input_groups.inputs.options`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async saveForm({state}, data) {
        return await authedAxios.post(`/policy-systems/${state.policySystem}/forms/`, utils.dataFormatter.serialize({stuff: data}));
    },
    async createClassCode({state}, data) {
        return await authedAxios.post(`/policy-systems/${state.policySystem}/class-codes/`, utils.dataFormatter.serialize({stuff: data}));
    },
    async createNewFormDraft({state}, {formItem, versionId}) {
        const response = await authedAxios.post(
            `/policy-systems/${state.policySystem}/forms/${formItem.id}/versions/?include=attachment,updated_by,created_by`,
            utils.dataFormatter.serialize({stuff: {id: versionId, type: 'Version'}})
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async createNewClassCodeDraft({state}, {classCode, versionId}) {
        const response = await authedAxios.post(
            `/policy-systems/${state.policySystem}/class-codes/${classCode.id}/versions/?include=updated_by,created_by`,
            utils.dataFormatter.serialize({stuff: {id: versionId, type: 'Version'}})
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async deleteAttachment(_, {version, id}) {
        const data = {id, type: 'Attachment', versionId: version.id};

        await authedAxios.delete(`/attachment/${id}/`, {
            data: utils.dataFormatter.serialize({stuff: data}),
            headers: {'content-type': 'application/vnd.api+json'},
            withCredentials: true,
        });
    },
    async deleteForm({state, dispatch}, {formId}) {
        await authedAxios.delete(`/policy-systems/${state.policySystem}/forms/${formId}`);

        dispatch('fetchForms');
    },
    async deleteClassCode({dispatch, state}, classCodeId) {
        await authedAxios.delete(`/policy-systems/${state.policySystem}/class-codes/${classCodeId}`);

        dispatch('fetchClassCodes', {queryString: ''});
    },
    async deleteFormVersion({state}, {form, versionId}) {
        const response = await authedAxios.delete(`/policy-systems/${state.policySystem}/forms/${form.id}/versions/${versionId}`);

        return response.status === 204;
    },
    async deleteClassCodeVersion({state}, {classCode, versionId}) {
        const response = await authedAxios.delete(`/policy-systems/${state.policySystem}/class-codes/${classCode.id}/versions/${versionId}`);

        return response.status === 204;
    },
    async obsoleteForm({state}, data) {
        const response = await authedAxios.patch(`/policy-systems/${state.policySystem}/forms/${data.id}/?include=updated_by,versions,versions.attachment,versions.updated_by,versions.created_by`,
            utils.dataFormatter.serialize({stuff: data}),
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async updateClassCode({state}, classCodeData) {
        const response = await authedAxios.patch(`/policy-systems/${state.policySystem}/class-codes/${classCodeData.id}/?include=updated_by,versions,versions.updated_by,versions.created_by`,
            utils.dataFormatter.serialize({stuff: {...classCodeData, type: 'ClassCode'}}),
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async updateForm({state}, data) {
        const response = await authedAxios.patch(`/policy-systems/${state.policySystem}/forms/${data.id}/?include=updated_by,versions.attachment,versions.updated_by,versions.created_by`,
            utils.dataFormatter.serialize({stuff: data})
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async unscheduleObsolete({state}, id) {
        await authedAxios.patch(`/policy-systems/${state.policySystem}/forms/${id}/?include=updated_by,versions.attachment,versions.updated_by,versions.created_by`,
            utils.dataFormatter.serialize({stuff: {obsoleteAt: null, renewalObsoleteAt: null, type: 'Form', id}}),
        );
    },
    async updateMultipleVersions({state}, {id, form, query = '', data}) {
        await authedAxios.patch(`/policy-systems/${state.policySystem}/forms/${form.id}/versions/${id}/?include=attachment,updated_by,created_by${query}`, utils.dataFormatter.serialize({stuff: {type: 'Version', ...data}}));
    },
    async updateSingleFormVersion({state}, {id, form, query = '', data = {}}) {
        const response = await authedAxios.patch(`/policy-systems/${state.policySystem}/forms/${form.id}/versions/${id}/?include=attachment,updated_by,created_by${query}`, utils.dataFormatter.serialize({stuff: {type: 'Version', ...data}}));

        return utils.dataFormatter.deserialize(response.data);
    },
    async updateClassCodeVersion({state}, {classCode, versionData, query = ''}) {
        const url = `/policy-systems/${state.policySystem}/class-codes/${classCode.id}/versions/${versionData.id}/?include=updated_by,created_by${query}`,
            response = await authedAxios.patch(url , utils.dataFormatter.serialize({stuff: {...versionData, type:'Version'}}));

        return utils.dataFormatter.deserialize(response.data);

    },
    async uploadFile(_, {data, url = 'attachment/'}) {
        let formData = new FormData();
        formData.append('file', data.file);
        if (data.companies) {
            formData.append('companies', JSON.stringify(data.companies || []));
        }

        if (data.number) {
            formData.append('number', data.number);
        }

        let response = await authedAxios.post(url, formData);

        return utils.dataFormatter.deserialize(response.data);
    },
};

export const mutations = {
    CLEAR_SEARCH_FIELDS(state, key) {
        Vue.set(state.searchFields, key, {});
    },
    RESET_ADVANCED_SEARCH(state) {
        Object.keys(state.advancedSearch).forEach(k => {
            (Array.isArray(state.advancedSearch[k]) ? Vue.set(state.advancedSearch, k, []) : Vue.set(state.advancedSearch, k, ''));
        });
    },
    SET_ADVANCED_SEARCH(state, fields) {
        for (let fieldName in fields) {
            Vue.set(state.advancedSearch, fieldName, fields[fieldName]);
        }
    },
    SET_ADDING_FORM(state, {adding, duplicatingForm = null}) {
        state.addingForm = adding;
        state.duplicatingForm = duplicatingForm;
    },
    SET_DATA(state, {field, data}) {
        state[field] = data;
    },
    SET_ADMIN_SIDE_MENU_COLLAPSED(state, collapsed) {
        state.admin.sideMenu.collapsed = collapsed;
    },
    SET_SEARCH_FIELDS(state, search) {
        if ((typeof search.value !== 'boolean' && !search.value) || (Array.isArray(search.value) && !search.value.length)) {
            Vue.delete(state.searchFields[search.type], search.key);
        } else {
            Vue.set(state.searchFields[search.type], search.key, search.value);
        }
    },
};

export const state = () => {
    return {
        addingForm: false,
        admin: {
            sideMenu: {
                collapsed: true,
            },
        },
        advancedSearch: {
            companyId: [],
            number: '',
            rndName: '',
            proposalName: '',
            helpWording: '',
            csrHelpWording: '',
            internalNotes: '',
            inclusionMethod: [],
            locationStates: [],
            mandatory: '',
            isCommon: '',
            screenName: '',
            status: [],
            state: [],
            userAvailability: '',
            showInAgentDashboard: '',
            agentDashboardAvailability: '',
            showInPolicySystem: '',
            hasPageVariableData: '',
            requiresSignature: '',
            shouldPrintVariableData: '',
            shouldPrintAtRenewal: '',
            formType: '',
        },
        blueprints: [],
        classCodes: [],
        duplicatingForm: null,
        editingForm: false,
        forms: [],
        policySystem: '',
        searchFields: {
            forms: {},
            classCodes: {}
        },
        totalClassCodeCount: 0,
        totalFormCount: 0,
    };
};

export default new Vuex.Store({
    getters,
    actions,
    mutations,
    state: state(),
    modules: {
        alerts,
        configuration,
        notifications,
        toolbox,
        webFormEditor,
        vueVersioning,
    },
});
